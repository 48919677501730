<template>
  <div>
    <v-card style="padding: 10px">
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <label for="supplier_name">{{ translate("Name") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="supplier_name"
              v-model="supplier_name"
              outlined
              dense
              :placeholder="translate('Name')"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="12">
            <v-btn color="primary" v-on:click="submit"> {{ translate("Submit") }} </v-btn>
            <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined>
              {{ translate("Cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import { newOrigin, newSupplier } from "@/ApiManager";

export default {
  methods: {
    cancel: function (_) {
      this.$router.go(-1);
    },
    submit: function (_) {
      newOrigin(this.supplier_name).then((response) => {
        this.$router.push({ name: "origins" });
      });
    },
  },
  data() {
    return { supplier_name: "", supplier_email: "", supplier_phone_number: "" };
  },
};
</script>
<style>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
</style>
